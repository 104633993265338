import styled from 'styled-components';

interface ScTopProps {
	background?: string;
	disableHeight?: boolean;
}

export const ScTop = styled.div<ScTopProps>`
	display: flex;
	flex-direction: column;
	padding-top: 65px;
	box-sizing: border-box;
	@media (min-width: 390px) {
		max-height: 100%;
		min-height: ${({ disableHeight }) => (disableHeight ? '100%' : '100svh')};
		@media (min-height: 500px) {
			max-height: ${({ disableHeight }) => (disableHeight ? '100%' : '100svh')};
		}
	}
	@media (min-width: 768px) {
		min-height: ${({ disableHeight }) => (disableHeight ? '100%' : '100vh')};
		max-height: 100%;
		@media (min-height: 1000px) {
			max-height: ${({ disableHeight }) => (disableHeight ? '100%' : '100vh')};
		}
	}
	@media (min-height: 600px) and (min-width: 1024px) {
		max-height: ${({ disableHeight }) => (disableHeight ? '100%' : '100vh')};
		min-height: 100vh;
	}
	background: ${({ theme, background }) => background || theme.colors.transparent};
	@media (min-width: 768px) {
		padding-top: 95px;
	}
	@media (min-width: 1220px) {
		padding-top: 120px;
		min-height: ${({ disableHeight }) => (disableHeight ? '100%' : '100vh')};
	}
`;
